<template>
  <el-dialog
    :title="title"
    :visible.sync="showDialog"
    width="50%"
    :modal-append-to-body="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div v-loading="loading">
      <el-form label-width="100px" :model="form">
        <el-form-item label="用戶名稱">{{ form.nickname }}</el-form-item>

        <el-form-item label="用戶ID">
          <a
            class="el-link el-link--primary is-underline"
            href="javascript:;"
            target="_blank"
            >{{ form.realname }}</a
          >
        </el-form-item>
        <el-form-item label="舉報者名称">{{ form.bnickname }}</el-form-item>
        <el-form-item label="舉報者ID">
          <a
            class="el-link el-link--primary is-underline"
            href="javascript:;"
            target="_blank"
            >{{ form.brealname }}</a
          >
        </el-form-item>
        <el-form-item label="聯繫方式">
          {{ form.contact }}
        </el-form-item>
        <el-form-item label="舉報原因">
          {{ form.why }}
        </el-form-item>
        <el-form-item label="審核結果">
          <el-radio v-model="form.status" :label="1">不予處理</el-radio>
          <el-radio v-model="form.status" :label="2">封禁用戶</el-radio>
        </el-form-item>
        <el-form-item label="審核意見">
          <el-input
            type="textarea"
            class="h100"
            v-model="form.opinion"
            placeholder="请输入審核意見，50字以內"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close()">取 消</el-button>
      <el-button type="primary" @click="confirm()" :loading="saving"
        >確 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      title: "審核商品",
      showDialog: false,
      form: {
        status: 1, //1.不予處理，2.封禁商品
        opinion: "",
      },
    }
  },
  watch: {
    show(val) {
      this.showDialog = val
      // 打开弹窗的时候请求数据
      if (val) {
        this.getGoodsInfo()
      }
    },
  },
  methods: {
    async getGoodsInfo() {
      this.loading = true
      let res = await this.api.report.getOneUserReport(this.item.id)
      if (res && res.code == 0) {
        this.form = { ...this.form, ...res.data }
      }
      this.loading = false
    },
    close() {
      this.$emit("close")
    },
    confirm() {
      this.saving = true
      this.$emit("confirm", {
        id: this.form.id,
        opinion: this.form.opinion,
        status: this.form.status,
      })
    },
  },
}
</script>
<style scoped>
/deep/.el-form-item__label {
  line-height: 1.2;
  align-items: center;
  display: flex;
  height: 40px;
}
</style>
