<template>
  <div ref="main" class="m-main-wrap" v-loading="loadList">
    <div ref="topDom">
      <!-- 搜索 start -->
      <div class="flex-l flex-wrap lhx40">
        <el-input
          class="w240 mr10 mb20"
          placeholder="请输入用戶名稱/ID/手機號碼"
          clearable
          v-model="filters.title"
          @clear="search"
          @keyup.enter.native="search"
        ></el-input>

        <div>
          <el-button class="mb20" type="primary" @click="search"
            >搜尋</el-button
          >
        </div>
      </div>
    </div>
    <!-- 内部侧边栏 -->
    <el-container>
      <el-main>
        <!-- 表格 -->
        <el-table
          :data="tableData.data"
          style="width: 100%"
          :height="tableHeight"
        >
          <el-table-column width="60" label="NO.">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column label="被舉報用戶ID" width="160">
            <a
              class="el-link el-link--primary is-underline"
              :href="
                constants.clientUrl + '/userDetails?userId=' + scope.row.buid
              "
              target="_blank"
              slot-scope="scope"
              >{{ scope.row.brealname }}</a
            >
          </el-table-column>
          <el-table-column label="被舉報用戶名稱" width="200" prop="bnickname">
          </el-table-column>
          <el-table-column label="舉報者ID" width="100">
            <a
              :href="
                constants.clientUrl + '/userDetails?userId=' + scope.row.uid
              "
              target="_blank"
              class="el-link el-link--primary is-underline"
              slot-scope="scope"
            >
              {{ scope.row.realname }}
            </a>
          </el-table-column>
          <el-table-column
            width="120"
            label="舉報者名稱"
            prop="nickname"
          ></el-table-column>
          <el-table-column label="提交時間" prop="create_time" width="140">
            <template slot-scope="scope">
              {{ scope.row.create_time | formatTime }}
            </template>
          </el-table-column>
          <el-table-column label="審核結果">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.status == 0">未審核</el-tag>
              <el-tag type="warning" v-if="scope.row.status == 1"
                >不予處理</el-tag
              >
              <el-tag type="danger" v-if="scope.row.status == 2"
                >封禁用戶</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="審核意見" width="100">
            <template slot-scope="scope">
              <el-popover
                v-if="scope.row.opinion"
                placement="top-start"
                title="審核意見"
                width="200"
                trigger="hover"
                :content="scope.row.opinion"
              >
                <div class="c-note" slot="reference">{{ scope.row.opinion }}</div>
              </el-popover>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column label="審核狀態">
            <template slot-scope="scope">
              <!-- 0.待審核，1.已審核 -->
              <el-tag v-if="scope.row.state == 0">待審核</el-tag>
              <el-tag type="success" v-if="scope.row.state == 1">已審核</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-link
                v-if="scope.row.state == 0"
                type="primary"
                @click="openCheckDialog(scope.$index)"
                >審核</el-link
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 ｜ 批量移动-->
        <div ref="btmDom" class="flex-r flex-mid pb10 pt10">
          <el-pagination
            background
            hide-on-single-page
            :page-size="tableData.per_page"
            :current-page="tableData.current_page"
            :total="tableData.total"
            @current-change="changePage"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>
    <checkUserVue
      ref="createDialog"
      :show="showDialog"
      :item="curItem"
      @close="showDialog = false"
      @confirm="confirm"
    ></checkUserVue>
  </div>
</template>
<script>
import { calTableHeight } from "@/common/tool"
import checkUserVue from "./components/checkUser.vue"
export default {
  components: { checkUserVue },
  data() {
    return {
      loadList: false,
      loadingNote: false,
      curItem: {},
      curIndex: -1,
      showDialog: false,
      filters: {
        title: "",
        rid: "",
        nid: "",
        min: "",
        max: "",
        offline: "",
        mail: "",
      },
      tableData: {
        data: [],
        current_page: 1,
        per_page: 15,
        total: 0,
      },
      tableHeight: 100, //表格高度
    }
  },
  created() {
    this.getDataList()
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = calTableHeight(this)
    })
  },
  methods: {
    async getDataList(page) {
      this.loadList = true
      let res = await this.api.report.getUserReports({
        page,
        ...this.filters,
      })
      if (res && res.code == 0) {
        this.tableData = res.data
      }
      this.loadList = false
    },
    openCheckDialog(index) {
      this.curIndex = index
      this.curItem = this.tableData.data[index]
      this.showDialog = true
    },
    search() {
      if (!isNaN(this.filters.max) && !isNaN(this.filters.min)) {
        if (
          this.filters.max &&
          this.filters.min &&
          this.filters.max < this.filters.min
        ) {
          this.$message({
            message: "最高價格不能小于最低價格",
            type: "warning",
          })
          return
        }
      }
      this.getDataList()
    },
    changePage(pageNum) {
      this.getDataList(pageNum)
    },
    confirm(data) {
      this.checkData(data)
    },
    async checkData(data) {
      let res = await this.api.report.createUserReport(data)
      if (res && res.code == 0) {
        this.getDataList(this.tableData.current_page)
        this.showDialog = false
        this.$message({
          message: "審核成功",
          type: "success",
        })
      }
      this.$refs["createDialog"].saving = false
    },
  },
}
</script>
<style scoped>
/deep/.el-form-item__label {
  color: #999;
}
/deep/.el-form--inline .el-form-item {
  padding-right: 20px;
}
</style>
